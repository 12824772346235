
import React from "react"

import Contact from "./contact";
import styled from "styled-components";

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: rgba(222, 222, 222, 0.7);
  `;


class Form extends React.Component {
  render() {

    return (
      <FormWrapper>
        <h2>Contact Us Today</h2>
        <Contact />
        <p>Don't like forms? You can email us at <a href="mailto:info@cleancatalog.com">info@cleancatalog.com.</a></p>
      </FormWrapper>
    )
  }
}

export default Form
