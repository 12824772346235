import React from "react"
import styled from "styled-components";
import Loader from "react-loader";

const ContactWrapper = styled.form`
    display: flex;
    flex-direction: column;
  `;


const StyledLabel = styled.label`
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${props => props.theme.fontSmall};
  display: flex;
  flex-direction: column;
  line-height: 1.2;
`;

const StyledInput = styled.input`
  border: solid 1px ${props => props.theme.red};
  padding: 10px 20px;
  font-weight: 100;
  margin-bottom: 30px;
`;


const StyledTextarea = styled.textarea`
  border: solid 1px ${props => props.theme.red};
  padding: 10px 20px;
  font-weight: 100;
  margin-bottom: 30px;
  resize: none;
  height: 6rem;
`;

const StyledButton = styled.input`
  display: inline-block;
  padding: 0.5rem 0;
  width: 11rem;
  background: ${props => props.theme.red};
  color: #fff;
  border: 2px solid ${props => props.theme.red};
  &:hover,
  &:focus {
    cursor: pointer;
    background: transparent;
    color: ${props => props.theme.red};
  }
`;


const LoaderWrapper = styled.div`
  position: relative;
  height: '90px';
`;

const ResponseText = styled.p`
  font-size: ${props => props.theme.fontMd};
  max-width: 100%;
`;


class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      responsemessage: '',
      submitting: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }


  handleName(event) {
    this.setState({'name': event.target.value});
  }

  handleEmail(event) {
    this.setState({'email': event.target.value});
  }

  handleMessage(event) {
    this.setState({'message': event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      'submitting': true
    });



    //@todo  First, validate email here so there's less chance of it getting rejected by hubspot

    // Then, if we have a name value, split it into two values for first and last name
    let firstName = this.state.name;
    let lastName = '';
    if (this.state.name.length > 0 && this.state.name.indexOf(' ') !== -1) {
      let names = this.state.name.split(' ');
      firstName = names[0];
      lastName = names[1];
    }

// This is the format Hubspot needs for submissions
    let data = {
      "submittedAt": Math.floor(Date.now()),
      "pageUrl": document.URL,
      "fields": [
        {
          "name": "email",
          "value": this.state.email
        },
        {
          "name": "firstname",
          "value": firstName
        },
        {
          "name": "firstname",
          "value": lastName
        }
      ]
    };


    const hubspotUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/7009511/791a4731-2e62-4c0b-b6d6-855df87801fa';


    fetch(hubspotUrl, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        this.setState({'submitting': false});
        if (responseJson.status === 'error') {
          if (responseJson.errors[0].errorType === 'INVALID_EMAIL') {
            this.setState({'responsemessage': 'Please enter a valid email address.'});
          } else {
            this.setState({'responsemessage': 'There was an error with your submission. Please try again, or email us at info@cleancatalog.com.'});
          }
        } else {
          this.setState({'responsemessage': 'Thanks! We\'ll be in touch shortly'});
        }

      })
      .catch((error) => {
        this.setState({'submitting': false});
        this.setState({'responsemessage': 'There was an error with your submission. Please try again, or email us at info@cleancatalog.com.'});
      })
    ;


  }



  render() {
    let submitButton = <StyledButton type="submit" value="SUBMIT" className="btn"/>;
    if (this.state.submitting === true) {
       submitButton = (
         <LoaderWrapper>
           <Loader loaded={!this.state.submitting} color='#f96e64' />
         </LoaderWrapper>
       )
    }
    return (
      <ContactWrapper onSubmit={this.handleSubmit}>
        <StyledLabel>
          Your Name
          <StyledInput type="text" name="name" placeholder="Your Name" onChange={this.handleName}/>
        </StyledLabel>
        <StyledLabel>
          Your Email
          <StyledInput type="text" name="_replyto" placeholder="Email" onChange={this.handleEmail}/>
        </StyledLabel>

        <StyledLabel>Your Message
          <StyledTextarea name="message" placeholder="Message" onChange={this.handleMessage}/>
        </StyledLabel>

        <ResponseText>{this.state.responsemessage}</ResponseText>
        {submitButton}

      </ContactWrapper>
    )

  }
}

export default Contact
