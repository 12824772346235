import React from "react"
import Icon from "./Icon";
import styled from "styled-components";

const StyledIcon = styled(Icon)`

  `;


const FeatureIcon = ({ ...props }) => {
  return <StyledIcon size='50px' color="#f96e64" {...props}/>;
};

export default FeatureIcon;
