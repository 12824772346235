import React from "react"
import styled from "styled-components";
import TextWrapper from "../styles/TextWrapper";

const LargeP = styled.p`
  font-size: ${props => props.theme.fontMd};

`;

const StyledSubtitle = styled.h2`
  font-size: ${props => props.theme.fontXl};
  color: ${props => props.color || "black"};
`;

class SectionHeading extends React.Component {
  render() {
    return (
      <TextWrapper>
        <StyledSubtitle color={this.props.color}>{this.props.title}</StyledSubtitle>
        <LargeP>{this.props.text}</LargeP>
      </TextWrapper>
    )
  }
}

export default SectionHeading
