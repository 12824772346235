
import React from "react"
import SectionHeading from "./SectionHeading";
import styled from "styled-components";
import Container from "../styles/Container";
import {graphql, useStaticQuery} from "gatsby";
import Image from "gatsby-image";


const CaseStudiesOuter = styled.div`
    background-color: #f9f9f9;
`;

const CaseStudiesContainer = styled(Container)`
  padding-top: 80px;
  padding-bottom: 80px;
`;


const LogoContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
   @media(max-width: ${props => props.theme.screenMdMax}) {
    flex-direction: column;
    > * {
      margin: 20px;
    }
   }
`;

const VillanovaWrapper = styled.div`
  padding: 10px;
`;


export default () => {

    const data = useStaticQuery(graphql`
      query {

        villanova: file(absolutePath: { regex: "/villanova.png/" }) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        },
       xusom: file(absolutePath: { regex: "/xum-logo.png/" }) {
          childImageSharp {
            fixed(width: 180) {
              ...GatsbyImageSharpFixed
            }
          }
        },
#        pencol: file(absolutePath: { regex: "/pencol.png/" }) {
#          childImageSharp {
#            fixed(width: 100) {
#              ...GatsbyImageSharpFixed
#            }
#          }
#        },

        army: file(absolutePath: { regex: "/salvation-army.png/" }) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        },


        nabcep: file(absolutePath: { regex: "/nabcep.png/" }) {
          childImageSharp {
            fixed(width: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        },
#        weatherford: file(absolutePath: { regex: "/weatherford.png/" }) {
#          childImageSharp {
#            fixed(width: 150) {
#              ...GatsbyImageSharpFixed
#            }
#          }
#        },
        faes: file(absolutePath: { regex: "/faes.png/" }) {
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        usi: file(absolutePath: { regex: "/usi.png/" }) {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        howard: file(absolutePath: { regex: "/howard.png/" }) {
          childImageSharp {
            fixed(width: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        },
      }
    `);




    return (
      <CaseStudiesOuter>
        <CaseStudiesContainer>
          <SectionHeading
            title='A few of our clients'
            color='black'
          />

          <LogoContainer>
            <VillanovaWrapper>
              <Image
                fixed={data.villanova.childImageSharp.fixed}
                alt="Villanova University Logo"
              />
            </VillanovaWrapper>

            {/*<Image*/}
            {/*  fixed={data.weatherford.childImageSharp.fixed}*/}
            {/*  alt="Weatherford College Logo"*/}
            {/*/>*/}

            <Image
              fixed={data.faes.childImageSharp.fixed}
              alt="FAES Logo"
            />
            <Image
              fixed={data.howard.childImageSharp.fixed}
              alt="Howard University Logo"
            />
          <Image
            fixed={data.xusom.childImageSharp.fixed}
            alt="Xusom University Logo"
          />

          <Image
            fixed={data.nabcep.childImageSharp.fixed}
            alt="Nabcep Logo"
          />


          {/*<Image*/}
          {/*  fixed={data.pencol.childImageSharp.fixed}*/}
          {/*  alt="Peninsula College Logo"*/}
          {/*/>*/}
          </LogoContainer>

        </CaseStudiesContainer>
      </CaseStudiesOuter>
    )

}


