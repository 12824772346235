import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Features from "../components/features";
import Customers from "../components/customers";
import SEO from "../components/seo";



export default () => {

  const data = useStaticQuery(graphql`
    query {
      logo: file(absolutePath: { regex: "/0J1A1120.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 3600) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `);


    return (
      <Layout>
        <SEO
          title={'Employee Handbook Software'}
          description={'Web-based employee handbook software for organizations of all sizes.'}

        />
        <Hero
          image={data.logo.childImageSharp.fluid}
          alt='Employee handbook software for organizations of all sizes.'
          title='Employee handbook software for organizations of all sizes.'
          text='Make your employee handbook easy to manage and easy to distribute — for an affordable annual cost with no upfront fee.'
        />

        <Customers />

        {/*<Pricing />*/}
        <Features />

      </Layout>
    )

}


