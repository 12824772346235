import React from "react"
import Container from "../styles/Container";
import styled from "styled-components";
import Form from "./form";
import Image from "gatsby-image";


const HeroHeadingWrapper = styled.div`
    max-width: 40rem;
    position: relative;
    @media(max-width: ${props => props.theme.screenMdMax}) {
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 10px;
    }
`;

const HeroHeading = styled.h1`
    color: #fff;
    font-size: ${props => props.theme.fontXl};
    @media(max-width: ${props => props.theme.screenMdMax}) {
      font-size: ${props => props.theme.fontMd};
      margin-bottom: 10px;
    }
  `;

const HeroWrapper = styled.div`
 position: relative;
 @media(max-width: ${props => props.theme.screenMdMax}) {
    position: relative;
  }
`;

const HeroContent = styled.div`
 @media(min-width: ${props => props.theme.screenMd}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const HeroContainer = styled(Container)`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 4rem;
  @media(max-width: ${props => props.theme.screenMdMax}) {
    padding: 0;
    flex-direction: column;
    position: unset;
  }
`;

const HeroText = styled.p`
  color: #fff;
  font-size: ${props => props.theme.fontMd};
   @media(max-width: ${props => props.theme.screenMdMax}) {
    line-height: 1.1;
    font-size: ${props => props.theme.fontSmall};
   }
`;

class Hero extends React.Component {

  render() {

    let text = null;
    if (this.props.text) {
      text = <HeroText>{this.props.text}</HeroText>
    }

    return (
      <HeroWrapper>
        <Image fluid={this.props.image} alt={this.props.alt}/>
        <HeroContent>
          <HeroContainer>
            <HeroHeadingWrapper>
              <HeroHeading>{this.props.title}</HeroHeading>
              {text}
            </HeroHeadingWrapper>
            <Form/>
          </HeroContainer>
        </HeroContent>
      </HeroWrapper>
    )
  }
}

export default Hero
