import React from "react"

import {graphql, StaticQuery} from "gatsby";
import FeatureIcon from "../icomoon/FeatureIcon";
import styled from "styled-components";
import Container from "../styles/Container";

import SectionHeading from "./SectionHeading";
import ReactTooltip from 'react-tooltip'


const CaseStudiesContainer = styled(Container)`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const FeaturesUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;

  @media(max-width: ${props => props.theme.screenMdMax}) {
    flex-direction: column;
  }
  }
`;

const FeaturesLi = styled.li`

    width: 30%;
    margin-top: 30px;
    margin-bottom: 30px;


    svg {
      margin-right: 20px;
      fill: black !important;
    }
    &:hover {
      cursor: pointer;
    }

    @media(max-width: ${props => props.theme.screenMdMax}) {
      width: 100%;
    }
`;


const StyledReactTooltip= styled(ReactTooltip)`
    background: black !important;
    max-width: 400px !important;
    font-size: ${props => props.theme.fontBase};
`;

const ItemWrapper= styled.div`

    display: flex;
    align-items: center;
    font-size: ${props => props.theme.fontMd2};
`;



class Features extends React.Component {


  render() {


    return (
      <CaseStudiesContainer>


        <SectionHeading
          title='Features & Technology'
          text='Clean Catalog Comes with All This Built In'
        />

        <FeaturesUl>
          <StaticQuery
            query={graphql`
            query {
              site {
                siteMetadata {
                  title
                }
              }
              allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
                edges {
                  node {
                    excerpt
                    fields {
                      slug
                    }
                    frontmatter {
                      title
                      type
                      description
                      icon
                    }
                  }
                }
              }
            }
    `}
            render={(data) => {

              // Get just features
              let posts = data.allMarkdownRemark.edges.filter((item) => {
                return item.node.frontmatter.type === 'feature';
              });


              return posts.map(({node}) => {

                return (
                  <FeaturesLi>
                    <ItemWrapper data-tip data-for={node.frontmatter.title}>
                      <FeatureIcon icon={node.frontmatter.icon}/>
                      {node.frontmatter.title}
                    </ItemWrapper>
                    <StyledReactTooltip
                      id={node.frontmatter.title}
                      place="right"
                      effect="solid"
                      mulitiline={true}

                    >
                     {node.frontmatter.description}
                    </StyledReactTooltip>
                  </FeaturesLi>
                )
              });

            }}


          />
        </FeaturesUl>
      </CaseStudiesContainer>
    )
  }
}

export default Features
